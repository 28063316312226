import React from "react";
import Layout from "layout";
import { Container, Row, Col } from "react-grid-system";
import useDeviceSize from "libs/hooks/useDeviceSize";
import Seo from "common/seo";
import healingOintmentImage from "../images/aquaphor/a-tube-of-aquaphor-healing-ointment.png";
import aquaphorBreathable from "../images/aquaphor/graphic-illustrating-the-breathable-barrier-of-aquaphor.png";
import chartImage from "../images/aquaphor/chat-comparing-the-ingredients-of-aquaphor-vs-vaseline.jpg";
import bandageShoulderImg from "../images/aquaphor/male-patient-getting-a-bandage-applied-to-his-shoulder.jpg";
import beforeThenImg from "../images/aquaphor/there's-before-and-then-there's-aquaphor-logo.png";

import {
    ProductTypeA,
    ProductTypeC,
} from "common/product-details";

import "./index.scss";

function HomePage() {
    const deviceSizes = useDeviceSize();

    return (
        <Layout pageClass="home">
            <Seo pageTitle="Aquaphor" pageDescription="Aquaphor Healing Ointment provides a protective, breathable barrier that slows scab formation and allows oxygen to enter the wound, creating an ideal healing environment." />


            {/* HERO SECTION */}
            <section className="fluid-section red-gradient-bg hero-banner">
                <div className="inner-centered-container">
                    <div className="hero-container">
                        <Row>
                            <Col md={12} lg={9} className={deviceSizes?.smUp ? "" : ""} order={{ xs: 2, sm: 2, md: 2, lg: 1 }}>
                                <ProductTypeC productAlt="A tube of Eucerin Eczema Relief Cream and a bottle of Eczema Relief Cream body wash">
                                    <div className="functions">
                                        <h4>Enhance your minor wound healing with</h4>
                                        <h1>an optimal healing environment</h1>
                                        <ul className="checkmarks white">
                                            <li>
                                                <span>NON-COMEDOGENIC</span>
                                            </li>

                                            <li>
                                                <span>HYPOALLERGENIC</span>
                                            </li>
                                            <li>
                                                <span>PRESERVATIVE-FREE</span>
                                            </li>
                                        </ul>
                                        <ul className="checkmarks white">
                                            <li>
                                                <span>FRAGRANCE-FREE</span>
                                            </li>
                                            <li>
                                                <span>SAFE FOR SENSITIVE SKIN</span>
                                            </li>
                                        </ul>
                                    </div>
                                </ProductTypeC>
                            </Col>
                            <Col md={12} lg={3} className={deviceSizes?.smUp ? "" : ""} order={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
                                <div className="cout-products">
                                    <img
                                        src={healingOintmentImage}
                                        alt="A tube of Aquaphor Healing Ointment®"
                                        className="hero-product"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>

            {/* LINK-BUTTON SECTION 1 */}
            <section className="inner-centered-container callout-with-links-bullets">
                <Container fluid>
                    <Row className="justify-center">
                        <Col sm={12} md={3} className={deviceSizes?.smUp ? "no-pr" : ""}>
                            <img src={healingOintmentImage} alt="A tube of Aquaphor Healing Ointment®" className="" />
                        </Col>
                        <Col sm={12} md={8} className={deviceSizes?.smUp ? "no-pr" : ""}>
                            <h2>
                                Aquaphor Healing Ointment<sup>&reg;</sup>
                            </h2>
                            <ul className="dblarrows light-blue">
                                <li>
                                    <span>
                                        Provides a breathable, protective barrier that creates an optimal healing environment
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        Helps aid in the natural healing process
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        Clinically demonstrated to be suitable for sensitive skin
                                    </span>
                                </li>
                            </ul>
                            <div className="links-wrap">
                                <span className="font-bold dblue-text mb-10">Available at these retailers:</span>
                                <br />
                                <ul className="link-target-button">
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.walmart.com/ip/Aquaphor-Healing-Ointment-Advanced-Therapy-Skin-Protectant-7-Oz-Tube/45800609?adsRedirect=true" target="_blank">Walmart</a>
                                    </li>
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.target.com/p/aquaphor-healing-ointment-skin-protectant-and-moisturizer-for-dry-and-cracked-skin-7oz/-/A-49125433#lnk=sametab" target="_blank">Target</a>
                                    </li>
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.cvs.com/shop/aquaphor-advanced-therapy-healing-ointment-1-75-oz-prodid-2160034" target="_blank">CVS</a>
                                    </li>
                                </ul>
                                <ul className="link-target-button">
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.walgreens.com/store/c/aquaphor-healing-ointment/ID=prod6312558-product" target="_blank">Walgreens</a>
                                    </li>
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.kroger.com/p/aquaphor-healing-ointment-advanced-therapy-skin-lotion/0007214001945?fulfillment=DELIVERY&searchType=default_search" target="_blank">Kroger</a>
                                    </li>
                                    <li>
                                        <a rel="noopener noreferrer" href="https://www.amazon.com/Aquaphor-Advanced-Therapy-Ointment-Protectant/dp/B0107QPFBU/ref=sr_1_2?keywords=aquaphor+healing+ointment&qid=1680620689&rdc=1&sprefix=aquaphor+hea%2Caps%2C126&sr=8-2" target="_blank">Amazon</a>
                                    </li>
                                </ul>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* BREATHABLE SECTION */}
            <div className="breathable-block">
                <section className="inner-centered-container breathable">
                    <ProductTypeA
                        productName="The Aquaphor<sup>&reg;</sup> approach goes beyond petrolatum"
                        productAlt="Graphic illustrating the breathable barrier of Aquaphor"
                        productImage={aquaphorBreathable}
                    >
                        <div className="functions">
                            <p>
                                Aquaphor Healing Ointment provides a protective, breathable barrier that slows scab formation and allows oxygen to enter the wound,  creating an ideal healing environment.
                            </p>

                        </div>
                    </ProductTypeA>
                </section>
            </div>

            {/* AQUAPHOR-DIFFERENCE SECTION */}
            <section className="aquaphor-difference__block">
                <div className="inner-centered-container">
                    <h2>See the Aquaphor difference</h2>
                    <div className="table-media">
                        <img src={chartImage} alt="Chat comparing the ingredients of Aquaphor vs Vaseline" />
                    </div>
                </div>
            </section>

            {/* BANDAGE SECTION */}
            <section className="callout-container fluid-section darkgray-gradient-bg full-with-section fluid-section--alt">
                <div className="inner-container inner-container2">
                    <Row className="align-center">
                        <Col md={12} lg={6} className={deviceSizes?.smUp ? "" : ""} order={{ xs: 2, md: 1 }}>
                            <ProductTypeC
                                categoryFontColor="#ffffff"
                                productName="Most minor wounds from an in-office procedure will heal in 7 to 10 days<sup>1</sup>">
                                <div className="functions">
                                    <ul className="dblarrows white">
                                        <li>Call the office if you experience a fever, red streaking from your wound, or green drainage</li>
                                        <li>
                                            After your wound heals, always use sun protection when outdoors
                                        </li>
                                    </ul>
                                </div>
                            </ProductTypeC>
                        </Col>
                        <Col md={12} lg={6} className={deviceSizes?.smUp ? "no-pr" : ""} order={{ xs: 1, md: 2 }}>
                            <div className="cout-img">
                                <img
                                    src={bandageShoulderImg}
                                    alt="Male patient getting a bandage applied to his shoulder"
                                    className="image"
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>

            {/* BEFORE-THEN SECTION */}

            <section className="before-then__block fluid-section">
                <div className="inner-centered-container">
                    <div className="before-then__block-media">
                        <img src={beforeThenImg} alt="There's before, and then there's Aquaphor logo" />
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default HomePage;
