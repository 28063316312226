import React from "react";

const ProductTypeC = ({
    categoryBgColor,
    categoryFontColor,
    categoryName,
    productName,
    calloutImage,
    className,
    children,
    ...props
}) => {
    return (
        <div {...props} className="product-details">
            {categoryName && <h3
                style={{
                    backgroundColor: categoryBgColor,
                    color: categoryFontColor,
                }}
                className="category-name"
            >
                {categoryName}
            </h3>}
            <h2
                className="product-name"
                dangerouslySetInnerHTML={{ __html: productName }}
            />
            {children}
        </div>
    );
};

export default ProductTypeC;
